import { getAddress } from '@ethersproject/address'
import memoize from 'lodash/memoize'
import { ChainId, Token } from '@madmeerkatfinance/sdk'

const mapping = {
  [ChainId.BSC]: 'smartchain',
  [ChainId.ARBITRUM]: 'arbitrum',
  [ChainId.CRONOS]: 'cronos',
  [ChainId.ETHEREUM]: 'ethereum',
}

const getTokenLogoURL = memoize(
  (token?: Token) => {
    if (token && mapping[token.chainId]) {
      return `/images/${token.chainId}/tokens/${getAddress(token.address)}.svg`
    }
    return null
  },
  (t) => `${t.chainId}#${t.address}`,
)

export const getTokenLogoURLByAddress = memoize(
  (address?: string, chainId?: number) => {
    if (address && chainId && mapping[chainId]) {
      return `/images/${chainId}/tokens/${getAddress(address)}.svg`
    }
    return null
  },
  (address, chainId) => `${chainId}#${address}`,
)

export default getTokenLogoURL
