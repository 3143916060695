import { BLOCKS_CLIENT, BLOCKS_CLIENT_ARB, BLOCKS_CLIENT_CRO, BLOCKS_CLIENT_ETH, INFO_CLIENT, INFO_CLIENT_ARB, INFO_CLIENT_CRO, INFO_CLIENT_ETH } from 'config/constants/endpoints'
import { infoClientETH, infoClient, infoStableSwapClient, infoClientArb, infoClientCRO } from 'utils/graphql'

import { ChainId } from '@madmeerkatfinance/sdk'
import {
  ETH_TOKEN_BLACKLIST,
  PCS_ETH_START,
  PCS_V2_START,
  TOKEN_BLACKLIST,
  BSC_TOKEN_WHITELIST,
  ETH_TOKEN_WHITELIST,
  PCS_ARB_START,
} from 'config/constants/info'

export type MultiChainName = 'BSC' | 'ETH' | 'ARB' | 'CRO'

export type MultiChainNameExtend = MultiChainName | 'BSC_TESTNET'

export const multiChainName = {
  [ChainId.BSC]: 'BSC',
  [ChainId.ETHEREUM]: 'ETH',
  [ChainId.ARBITRUM]: 'ARB',
  [ChainId.CRONOS]: 'CRO',
  [ChainId.BSC_TESTNET]: 'BSC_TESTNET',
}

export const multiChainQueryMainToken = {
  BSC: 'BNB',
  ETH: 'ETH',
  ARB: 'ETH',
  CRO: 'CRO',
}

export const multiChainBlocksClient = {
  BSC: BLOCKS_CLIENT,
  ETH: BLOCKS_CLIENT_ETH,
  ARB: BLOCKS_CLIENT_ARB,
  CRO: BLOCKS_CLIENT_CRO,
  BSC_TESTNET: 'https://api.thegraph.com/subgraphs/name/lengocphuc99/bsc_testnet-blocks',
}

export const multiChainStartTime = {
  BSC: PCS_V2_START,
  ARB: PCS_ARB_START,
  CRO: PCS_ARB_START,
  ETH: PCS_ETH_START,
}

export const multiChainId = {
  BSC: ChainId.BSC,
  ETH: ChainId.ETHEREUM,
  ARB: ChainId.ARBITRUM,
  CRO: ChainId.CRONOS,
}

export const multiChainPaths = {
  [ChainId.ARBITRUM]: '',
  [ChainId.CRONOS]: '/cro',
  [ChainId.ETHEREUM]: '/eth',
}

export const multiChainQueryClient = {
  BSC: infoClient,
  ARB: infoClientArb,
  ETH: infoClientETH,
  CRO: infoClientCRO,
}

export const multiChainQueryEndPoint = {
  BSC: INFO_CLIENT,
  ARB: INFO_CLIENT_ARB,
  ETH: INFO_CLIENT_ETH,
  CRO: INFO_CLIENT_CRO,
}

export const multiChainScan = {
  BSC: 'BscScan',
  ARB: 'ArbiScan',
  CRO: 'CronoScan',
  ETH: 'EtherScan',
}

export const multiChainTokenBlackList = {
  BSC: TOKEN_BLACKLIST,
  ETH: ETH_TOKEN_BLACKLIST,
  ARB: ['0x72b169ad8af6c4fb53056b6a2a85602ad6863864'],
}

export const multiChainTokenWhiteList = {
  BSC: BSC_TOKEN_WHITELIST,
  ETH: ETH_TOKEN_WHITELIST,
}

export const getMultiChainQueryEndPointWithStableSwap = (chainName: MultiChainName) => {
  const isStableSwap = checkIsStableSwap()
  if (isStableSwap) return infoStableSwapClient
  return multiChainQueryClient[chainName]
}

export const checkIsStableSwap = () => window.location.href.includes('stableSwap')
